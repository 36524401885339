<template>
  <b-container>
    <b-row class="mt-5">
      <b-col>
        <el-card class="px-3 py-3">
          <b-row align-h="between">
            <b-col lg="4">
              <el-image :src="profile" :preview-src-list="profileList" class="rounded-lg" />
              <p class="text-center text-secondary">({{ $t('profileTip') }})</p>
            </b-col>
            <b-col lg="7">
              <p class="mb-3"><span class="h4 font-weight-bold">{{ info.name }}</span><span class="text-secondary pl-3">{{ info.userId }}</span></p>
              <b-row>
                <b-col cols="4" lg="3" class="my-2">
                  <span class="text-secondary">
                    {{ $t('info.gender') }}
                  </span>
                </b-col>
                <b-col cols="8" class="my-2">
                  <span>
                    {{ info['gender'] === 0 ? $t('info.male') : $t('info.female') }}
                  </span>
                </b-col>
                <b-col cols="4" lg="3" class="my-2">
                  <span class="text-secondary">
                    {{ $t('info.nationality') }}
                  </span>
                </b-col>
                <b-col cols="8" class="my-2">
                  <span v-if="$i18n.locale === 'zh'">
                    {{ info['nationality'].split('-')[1] }}
                  </span>
                  <span v-if="$i18n.locale === 'en'">
                    {{ info['nationality'].split('-')[0] }}
                  </span>
                </b-col>
                <b-col cols="4" lg="3" class="my-2">
                  <span class="text-secondary">
                    {{ $t('info.age') }}
                  </span>
                </b-col>
                <b-col cols="8" class="my-2">
                  <span>
                    {{ info['age'] }}
                  </span>
                </b-col>
                <b-col cols="4" lg="3" class="my-2">
                  <span class="text-secondary">
                    {{ $t('info.height') }}
                  </span>
                </b-col>
                <b-col cols="8" class="my-2">
                  <span>
                    {{ info['height'] }}
                  </span>
                </b-col>
                <b-col cols="4" lg="3" class="my-2">
                  <span class="text-secondary">
                    {{ $t('info.size') }}
                  </span>
                </b-col>
                <b-col cols="8" class="my-2">
                  <span>
                    {{ info['size'] }}
                  </span>
                </b-col>
                <b-col cols="4" lg="3" class="my-2">
                  <span class="text-secondary">
                    {{ $t('info.otherLang') }}
                  </span>
                </b-col>
                <b-col cols="8" class="my-2">
                  <span>
                    {{ info['otherLang'] }}
                  </span>
                </b-col>
                <b-col cols="4" lg="3" class="my-2">
                  <span class="text-secondary">
                    {{ $t('info.category') }}
                  </span>
                </b-col>
                <b-col cols="8" class="my-2">
                  <span v-html="info['category']" />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </el-card>
      </b-col>
    </b-row>
    <b-row class="mt-lg-5">
      <b-col v-if="info['video_intro']">
        <h4 class="my-3">
          <strong>{{ $t('profile') }}</strong>
        </h4>
        <div class="text-center videoBox">
          <video style="height: 100%" :src="info['video_intro']" controls="controls" />
        </div>
      </b-col>
      <b-col v-if="info['video_live']">
        <h4 class="my-3">
          <strong>{{ $t('video') }}</strong>
        </h4>
        <div class="text-center videoBox">
          <video style="height: 100%" :src="info['video_live']" controls="controls" />
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { fetchDetails } from '@/api/hosts'

export default {
  name: 'Details',
  data() {
    return {
      info: {},
      profile: '',
      profileList: []
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      fetchDetails({ id: this.$route.query.id }).then(e => {
        this.info = e.data
        this.info['category'] = this.info['category'].replaceAll(',', ' ')
        this.profile = e.data['pic_personal2']
        Object.keys(e.data).forEach(item => {
          if (item.indexOf('pic_') === 0) {
            this.profileList.push(e.data[item])
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.videoBox {
  width: 100%;
  height: 500px;
  background-color: #000000
}
</style>
